export const claimConfigs = [
  { date: new Date('2022-09-01T00:00:00.00+00:00'), unit: 909 },
  { date: new Date('2023-01-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-02-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-03-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-04-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-05-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-06-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-07-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-08-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-09-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-10-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-11-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2023-12-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2024-01-01T00:00:00.00+00:00'), unit: 1234 },
  { date: new Date('2024-02-01T00:00:00.00+00:00'), unit: 1231 },
];

export const UNIT_ALL_PRICE = claimConfigs.reduce((prev, { unit }) => prev + unit, 0);
