import React from 'react';
import axios from 'axios';

// Test: https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xe2BFd865EEB54Dc4e472a472c205C9F8e5A62Af9&startblock=0&endblock=99999999&page=1&offset=10000&sort=asc&apikey=95MAHS5DFM342Y2CPD7SMF5F365IECXE3A
const getTransactions = async (account: string, page = 1): Promise<any[]> => {
  let {
    data: { result: transactions },
  } = await axios.get(process.env.REACT_APP_BSC_API || '', {
    headers: {
      'Content-type': 'application/json',
    },
    params: {
      module: 'account',
      action: 'txlist',
      address: account,
      startblock: 0,
      endblock: 99999999,
      page,
      offset: 9000,
      sort: 'desc',
      apikey: process.env.REACT_APP_BSC_API_TOKEN,
    },
  });

  const hasMore = transactions.length === 9000;
  transactions = transactions.filter(
    (transaction: any) =>
      transaction?.isError === '0' &&
      transaction?.to?.toLowerCase() === process.env.REACT_APP_CONTRACT_ADDRESS?.toLowerCase(),
  );

  if (hasMore) return [...transactions, ...(await getTransactions(account, page + 1))];
  return transactions;
};

const useTransactions = (account?: string) => {
  const [transactions, setTransactions] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (!account) return;
    setLoading(true);
    getTransactions(account)
      .then((transactions) => {
        setTransactions(transactions);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  }, [account]);

  return { transactions, loading };
};

export default useTransactions;
