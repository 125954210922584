import React from 'react';
import { useEthers } from '@usedapp/core';
import moment from 'moment';
import { convertBigNumberToNumber, formatNumber } from '../../helpers/number';
import useContractCall from '../../hooks/useContractCall';
import './index.css';
import useCountDown from '../Invest/hooks/useCountDown';
import { claimConfigs, UNIT_ALL_PRICE } from '../../helpers/config';
import axios from 'axios';
import { toast } from 'react-toastify';

const Claim = () => {
  const { account } = useEthers();
  const [tokenBUSD] = useContractCall({ method: 'buyers', args: [account] });
  const tokenBUSDNumber = convertBigNumberToNumber(tokenBUSD) || 0;
  const showDetail = typeof tokenBUSD === 'undefined' || !!tokenBUSDNumber;

  const [claimedItems, setClaimedItems] = React.useState<any[]>([]);
  const [claimedLoading, setClaimedLoading] = React.useState(true);

  React.useEffect(() => {
    if (!account) return;
    setClaimedLoading(true);
    axios
      .get(`https://invest.ikonomy.io/account.php?method=get_claim&user_id=${account}`)
      .then(({ data }) => {
        setClaimedItems(data?.claimed_items || []);
        setClaimedLoading(false);
      });
  }, [setClaimedItems, setClaimedLoading, account]);

  if (!account) return <main className="bg-main"></main>;
  return (
    <main className="bg-main">
      <div className="container">
        <div className="py-3">
          <div className="d-block">
            <h2 className="fw-bold">IKT PRIVATE SALE</h2>
            <p>
              <span className="fw-light">Smart Contract: </span>
              <span className="fw-bold text-warning text-break">
                0x2A59EB6d94187D318871178C06453b3FA2027278
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="py-3">
          <div className="row g-5 py-3">
            {showDetail && (
              <div className="col-lg-12 col-xl-8">
                <div className="custom-card">
                  <div className="custom-card-content">
                    <div className="scroll">
                      <ul className="timeline">
                        {claimConfigs.map((claimConfig, index) => {
                          const tokenIKT = claimConfig.unit * Math.round(tokenBUSDNumber / 1000);
                          return (
                            <ClaimItem
                              date={claimConfig.date}
                              tokenIKT={tokenIKT}
                              key={index}
                              index={index}
                              account={account}
                              claimedLoading={claimedLoading}
                              claimedItems={claimedItems}
                              setClaimedItems={setClaimedItems}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={`col-lg-12 col-xl-4 ${showDetail ? '' : 'offset-xl-4'}`}>
              <div className="custom-card">
                <div className="custom-card-content">
                  <h4 className="fw-bold pb-2">SUMMARY</h4>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                      <p>Total Token Purchased:</p>
                    </div>
                    <div className="col-auto">
                      <p>{formatNumber(UNIT_ALL_PRICE * Math.round(tokenBUSDNumber / 1000))} IKT</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto" style={{ paddingRight: 0 }}>
                      <p>Total Invesment Amount:</p>
                    </div>
                    <div className="col-auto" style={{ paddingLeft: 0 }}>
                      <p>
                        {typeof tokenBUSD !== 'undefined' ? formatNumber(tokenBUSDNumber) : '--'}{' '}
                        BUSD
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                      <p>Total Token Claimed:</p>
                    </div>
                    <div className="col-auto">
                      <p>0 IKT</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                      <p>Vesting Period:</p>
                    </div>
                    <div className="col-auto">
                      <p>24 Months</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                      <p>TGE Unlock Rate:</p>
                    </div>
                    <div className="col-auto">
                      <p>5%</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                      <p>Month Cliff:</p>
                    </div>
                    <div className="col-auto">
                      <p>3 Months</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                      <p>Linear Vesting in:</p>
                    </div>
                    <div className="col-auto">
                      <p>14 Months</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

type ClaimItemProps = {
  index: number;
  date: Date;
  tokenIKT: number;
  account: string;
  claimedLoading: boolean;
  claimedItems: any[];
  setClaimedItems: (items: any[]) => any;
};
const ClaimItem: React.FC<ClaimItemProps> = ({
  date,
  tokenIKT,
  index,
  account,
  claimedLoading,
  claimedItems,
  setClaimedItems,
}) => {
  let order = index + 1;
  let prefix = ['', 'st', 'nd', 'rd'][order > 20 ? order % 10 : order] || 'th';
  const dateString = useCountDown(date);
  const requestTime = moment(date).utcOffset(0).format('HH:mm (UTC) MMM Do, YYYY');
  const [claiming, setClaiming] = React.useState(false);
  return (
    <li className="event" data-date={requestTime}>
      <h4 className="fw-bold mb-3">
        {order}
        {prefix}
      </h4>
      <div className="custom-card">
        <div className="custom-card-content container px-3 py-3 px-sm-4 py-sm-4">
          <div className="row d-flex justify-content-between">
            <div className="col-auto">
              <p className="fw-lighter fs-6 mb-0">Token Claim</p>
              <h4 className="fw-bold mb-0">{formatNumber(tokenIKT)} IKT</h4>
            </div>
            {!claimedLoading && !!tokenIKT && (
              <div className="col-auto py-2 align-self-center">
                {(() => {
                  if (moment(date) > moment()) {
                    return (
                      <button
                        type="button"
                        className="btn text-warning border border-warning rounded-pill float-end"
                      >
                        To Be Claimed
                      </button>
                    );
                  }
                  if (
                    claimedItems.find((claimedItem) => claimedItem.request_time === requestTime)
                  ) {
                    return (
                      <button
                        type="button"
                        className="btn text-warning border border-warning rounded-pill float-end"
                      >
                        To Be Claimed
                      </button>
                    );
                  }
                  return (
                    <button
                      type="button"
                      className="btn text-black bg-button-2 rounded-pill float-end"
                      onClick={() => {
                        setClaiming(true);
                        const loadingId = toast.loading('Updating Referral Code');
                        axios
                          .post(
                            `https://invest.ikonomy.io/account.php`,
                            `method=claim&user_id=${account}&request_time=${requestTime}`,
                          )
                          .then(async () => {
                            const { data } = await axios.get(
                              `https://invest.ikonomy.io/account.php?method=get_claim&user_id=${account}`,
                            );
                            setClaimedItems(data?.claimed_items || []);
                            toast.dismiss(loadingId);
                            toast('Claim success', { type: 'success' });
                            setClaiming(false);
                          })
                          .catch(() => {
                            toast.dismiss(loadingId);
                            setClaiming(false);
                          });
                      }}
                      disabled={claiming}
                    >
                      Claim now
                    </button>
                  );
                })()}
              </div>
            )}
            <p className="fw-lighter fs-6 mb-0">
              Start Claiming in: <span className="text-warning fw-bold">{dateString}</span>
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Claim;
