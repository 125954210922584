import { useCall } from '@usedapp/core';
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { contractABI } from './config';

export const iktContract = new ethers.utils.Interface(contractABI);

const useContractCall = (options: { method: string; args?: any[] }) => {
  const { value, error } =
    useCall({
      contract: new Contract(process.env.REACT_APP_CONTRACT_ADDRESS || '', iktContract),
      args: [],
      ...options,
    }) || {};
  return value || [];
};

export default useContractCall;
