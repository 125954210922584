import React from 'react';
import { useEthers } from '@usedapp/core';
import { toast } from 'react-toastify';
import './index.css';
import useTransactions from './useTransactions';

import { iktContract } from '../../hooks/useContractCall';
import moment from 'moment';
import { formatNumber } from '../../helpers/number';
import axios from 'axios';

const Account = () => {
  const { account } = useEthers();
  const [refCode, setRefCode] = React.useState('');
  React.useEffect(() => {
    if (!account) return;
    axios
      .get(`https://invest.ikonomy.io/account.php?method=get_user&user_id=${account}`)
      .then(({ data }) => {
        setRefCode(data.code);
      });
  }, [account]);

  const { transactions, loading } = useTransactions(account);

  if (!account) return <main className="bg-main"></main>;
  return (
    <main className="bg-main">
      <div className="container">
        <div className="row pt-5 justify-content-end">
          <div className="col-12 col-md-5 col-lg-5 align-self-center">
            <h1 className="fw-bold text-end">
              Refer Friends. <br />
              Earn IKT Together
            </h1>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div className="custom-card">
              <div className="custom-card-content">
                <div className="row">
                  <div className="col-4">
                    <p>Referral Code:</p>
                  </div>
                  <div className="col-8 text-end">
                    <p
                      className="fw-bold pointer"
                      onClick={() => {
                        if (!refCode) return false;
                        navigator.clipboard.writeText(refCode);
                        toast('Copy success', { type: 'success' });
                      }}
                    >
                      {refCode || "---"}
                      <img
                        src="/images/copy.svg"
                        className="fill-white"
                        style={{ width: 12, height: 12, marginLeft: 8 }}
                      />
                    </p>
                  </div>
                  <div className="col-4">
                    <p>Referral Link:</p>
                  </div>
                  <div className="col-8 text-end">
                    <p
                      className="fw-bold pointer"
                      onClick={() => {
                        if (!refCode) return false;
                        navigator.clipboard.writeText(
                          `https://invest.ikonomy.io/invest?ref=${refCode}`,
                        );
                        toast('Copy success', { type: 'success' });
                      }}
                    >
                      https://invest.ikonomy.io/invest?ref={refCode || "---"}
                      <img
                        src="/images/copy.svg"
                        className="fill-white"
                        style={{ width: 12, height: 12, marginLeft: 8 }}
                      />
                    </p>
                  </div>
                  {/* <div className="col-4">
                    <p className="mb-0">Email:</p>
                  </div>
                  <div className="col-8 text-end">
                    <p className="mb-0 fw-light">Add email</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5 mx-1">
          <div className="custom-card">
            <div className="custom-card-content">
              <h4 className="fw-bold">HISTORY</h4>
              {!!loading && (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              )}
              <div className="scroll">
                {transactions.map((transaction: any) => {
                  let methodName = '';
                  let value = 0;

                  try {
                    const { name } = iktContract.parseTransaction({
                      data: transaction.input,
                    });
                    const pkg = {
                      silverBuy: { name: 'Silver', value: 1000 },
                      goldBuy: { name: 'Gold', value: 2000 },
                      titanBuy: { name: 'Titan', value: 5000 },
                      platinumBuy: { name: 'Platinum', value: 10000 },
                      diamondBuy: { name: 'Diamond', value: 15000 },
                    }[name];
                    methodName = pkg?.name || '';
                    value = pkg?.value || 0;
                  } catch (error) {}

                  if (!methodName) return null;

                  return (
                    <div className="row pb-3" key={transaction?.hash}>
                      <div className="col-12 col-md-3">
                        <div className="text-sm-end text-start pt-sm-0 pt-2 fw-light">
                          {moment(parseInt(transaction.timeStamp) * 1000)
                            .utcOffset(0)
                            .format('HH:mm (UTC)')}
                          <br />
                          {moment(parseInt(transaction.timeStamp) * 1000)
                            .utcOffset(0)
                            .format('MMM Do, YYYY')}
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        {/* <div className="fw-bold">Private Sale Deposit</div> */}
                        <div className="fw-light text-break">
                          From BSC Address: {transaction.from}
                          <br />
                          To: {transaction.to}
                          <br />
                          Package: {methodName} - Value: {formatNumber(value)} BUSD
                        </div>
                        <div className="fw-lighter">
                          <a
                            target="_blank"
                            className="link-info"
                            href={`${process.env.REACT_APP_BSC_SCAN_BASE_URL}/tx/${transaction.hash}`}
                          >
                            View transaction on explorer
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Account;
