import { useContractFunction as useContractFunctionCore } from '@usedapp/core';
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { contractBNBABI } from './config';

const useContractBNBFunction = (method: string) => {
  return useContractFunctionCore(
    new Contract(
      process.env.REACT_APP_BNB_CONTRACT_ADDRESS || '',
      new ethers.utils.Interface(contractBNBABI),
    ),
    method,
  );
};

export default useContractBNBFunction;
