import React from 'react';
import useCountDown from './hooks/useCountDown';
import useContractCall from '../../hooks/useContractCall';
import {
  convertBigNumberToNumber,
  convertNumberToBigNumber,
  formatNumber,
} from '../../helpers/number';
import './index.css';
import useContractBNBFunction from '../../hooks/useContractBNBFunction';
import { useEthers } from '@usedapp/core';
import useContractFunction from '../../hooks/useContractFunction';
import useContractBNBCall from '../../hooks/useContractBNBCall';
import { toast } from 'react-toastify';
import { UNIT_ALL_PRICE } from '../../helpers/config';
import axios from 'axios';

type PackageType = 'silver' | 'gold' | 'titan' | 'platinum' | 'diamond';
const rate = parseFloat(process.env.REACT_APP_IKT_RATE || '1');
const pkgs = [
  { type: 'silver', name: 'Silver', total: 100, amount: 1000, ikt: UNIT_ALL_PRICE },
  { type: 'gold', name: 'Gold', total: 150, amount: 2000, ikt: UNIT_ALL_PRICE * 2 },
  { type: 'titan', name: 'Titan', total: 180, amount: 5000, ikt: UNIT_ALL_PRICE * 5 },
  { type: 'platinum', name: 'Platinum', total: 60, amount: 10000, ikt: UNIT_ALL_PRICE * 10 },
  { type: 'diamond', name: 'Diamond', total: 20, amount: 15000, ikt: UNIT_ALL_PRICE * 15 },
];
const Invest = () => {
  const endPoolTime = useCountDown(new Date('2022-10-10T00:00:00.00+00:00'));
  const [pkgType, setPkgType] = React.useState<PackageType>('silver');

  return (
    <main className="bg-main">
      <div className="container">
        <div className="py-3">
          <div className="d-block">
            <h2 className="fw-bold">IKT PRIVATE SALE</h2>
            <p className="text-break">
              <span className="fw-light">Smart Contract:</span>
              <span className="fw-bold text-warning text-break">
                0x2A59EB6d94187D318871178C06453b3FA2027278
              </span>
            </p>
          </div>
        </div>
        <div className="py-3">
          <div className="row g-5">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xl-6">
              <div className="custom-card">
                <div className="custom-card-content">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-auto">
                      <span className="fw-light">Total Supply</span>
                    </div>
                    <div className="col-auto">
                      <span className="fs-4 fw-bold">1,000,000,000 IKT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xl-6">
              <div className="custom-card">
                <div className="custom-card-content">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-auto">
                      <span className="fw-light">Private Sales Supply</span>
                    </div>
                    <div className="col-auto">
                      <span className="fs-4 fw-bold">40,000,000 IKT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xl-6">
              <div className="custom-card">
                <div className="custom-card-content">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-auto">
                      <span className="fw-light">Swap Rate</span>
                    </div>
                    <div className="col-auto">
                      <span className="fs-4 fw-bold">1 IKT = {rate} BUSD</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xl-6">
              <div className="custom-card">
                <div className="custom-card-content">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-auto">
                      <span className="fw-light">End Pool Time</span>
                    </div>
                    <div className="col-auto">
                      <span className="fs-4 fw-bold">{endPoolTime}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-5">
            <div className="col">
              <div className="custom-card">
                <div className="custom-card-content">
                  <div className="row">
                    <div className="col-lg-12 col-xl-6">
                      <div className="row">
                        <h3 className="fw-bold">PACKAGES AVAILABLE</h3>
                        <div className="col-5 col-sm-2 p-4 pb-0">
                          <div className="form-check form-check-inline ps-0 fw-light">
                            {pkgs.map((pkg) => (
                              <label className="form-check py-2" key={pkg.type}>
                                <input
                                  className="form-check-input input-scale"
                                  type="radio"
                                  checked={pkg.type === pkgType}
                                  onChange={() => setPkgType(pkg.type as any)}
                                />
                                <div className="form-check-label">{pkg.name}</div>
                              </label>
                            ))}
                          </div>
                        </div>
                        <div className="col-7 col-sm text-center">
                          <div className="py-3">
                            <img
                              alt="armorial"
                              className="box-shadow text-center"
                              style={{ width: 222, height: 'auto', maxWidth: '100%' }}
                              src={`/images/${pkgType}.png`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 p-4 pb-0">
                      <PackageDetail pkgType={pkgType} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

type PackageDetailProps = {
  pkgType: PackageType;
};
const PackageDetail: React.FC<PackageDetailProps> = ({ pkgType }) => {
  const [code, setCode] = React.useState(
    new URL(window.location.href).searchParams.get('ref') || '',
  );
  const prevCodeRef = React.useRef(new URL(window.location.href).searchParams.get('ref') || '');
  const { account } = useEthers();
  const pkg = pkgs.find((pkg) => pkg.type === pkgType);
  const total = pkg?.total || 0;
  const amountNumber = pkg?.amount || 0;
  const [remain] = useContractCall({ method: `${pkgType}Remain` });
  const remainNumber = remain?.toNumber();
  const loading = typeof remain === 'undefined';

  const [allowance] = useContractBNBCall({
    method: 'allowance',
    args: [account, process.env.REACT_APP_CONTRACT_ADDRESS],
  });
  const allowanceNumber = convertBigNumberToNumber(allowance) || 0;

  const bnbApprove = useContractBNBFunction('approve');
  const buy = useContractFunction(`${pkgType}Buy`);

  React.useEffect(() => {
    if (prevCodeRef.current !== code) {
      prevCodeRef.current = code;
      return;
    }
    if (buy.state.status === 'Exception') {
      toast.dismiss();
      toast(buy.state.errorMessage, { type: 'error' });
    } else if (buy.state.status === 'PendingSignature') {
      toast.loading('Transaction pending');
    } else if (buy.state.status === 'Success') {
      toast.dismiss();
      toast('Transaction Success', { type: 'success' });
      if (code) {
        const loadingId = toast.loading('Updating Referral Code');
        const user_id = buy.state.transaction?.from;
        const txhash = buy.state.transaction?.hash;
        axios
          .post(
            `https://invest.ikonomy.io/account.php`,
            `method=create_transaction&package=${pkgType}&code=${code}&txhash=${txhash}&user_id=${user_id}`,
          )
          .then(() => {
            toast.dismiss(loadingId);
            toast('Referral Code Success', { type: 'success' });
          });
      }
    }
  }, [buy.state.status, code, prevCodeRef, pkgType]);

  const onBuy = async () => {
    buy.resetState();

    if (!account || !remain) return;
    if (allowanceNumber < amountNumber) {
      await bnbApprove.send(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        convertNumberToBigNumber(100000),
      );
    }

    await buy.send();
  };

  return (
    <div className="p-relative">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="row d-flex justify-content-between">
        <div className="col-auto">
          <p className="fw-light">Investment Amount:</p>
        </div>
        <div className="col-auto">
          <p>{amountNumber ? formatNumber(amountNumber) : '--'} BUSD</p>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-auto">
          <p className="fw-light">Token Price:</p>
        </div>
        <div className="col-auto">
          <p>{rate} BUSD</p>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-auto">
          <p className="fw-light">Token Amount:</p>
        </div>
        <div className="col-auto">
          <p className="float-end">{pkg?.ikt ? formatNumber(pkg.ikt) : '--'} IKT</p>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-auto">
          <p className="fw-light">Total Packages:</p>
        </div>
        <div className="col-auto">
          <p className="float-end">{total}</p>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-auto">
          <p className="fw-light">Package Remains:</p>
        </div>
        <div className="col-auto">
          <p></p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="processing">
            <div
              className="processing__bar"
              style={{
                width:
                  typeof remainNumber !== 'undefined'
                    ? ((total - remainNumber) * 100) / total + '%'
                    : 0,
              }}
            >
              <div className="processing__dot">
                <div className="processing__value">
                  {typeof remainNumber === 'undefined' ? '__' : remainNumber}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3 justify-content-between">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xl-6">
          <input
            className="bg-button-1 px-4 m-full-width m-mb-2"
            type="text"
            placeholder="Enter Referral Code"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xl-6">
          <button type="button" className="float-end bg-button m-full-width" onClick={onBuy}>
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invest;
