import { useCall } from '@usedapp/core';
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { contractBNBABI } from './config';

const useContractBNBCall = (options: { method: string; args?: any[] }) => {
  const { value, error } =
    useCall({
      contract: new Contract(
        process.env.REACT_APP_BNB_CONTRACT_ADDRESS || '',
        new ethers.utils.Interface(contractBNBABI),
      ),
      args: [],
      ...options,
    }) || {};
  return value || [];
};

export default useContractBNBCall;
