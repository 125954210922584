import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { toast } from 'react-toastify';

type StandardLayoutProps = {
  children?: React.ReactNode;
};
const StandardLayout: React.FC<StandardLayoutProps> = ({ children }) => {
  const { activateBrowserWallet, account, deactivate } = useEthers();
  const history = useHistory();
  // const userBalance = useEtherBalance(account);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-header py-3">
        <div className="container">
          <Link className="navbar-brand" to="/invest">
            <div className="me-auto d-flex align-items-center">
              <img className="img-logo" src="/images/logo.png" />
            </div>
          </Link>
          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse text-end justify-content-end" id="navbarToggler">
            <ul className="navbar-nav mb-2 mb-lg-0 text-end">
              {!!account && (
                <>
                  <li className="nav-item ms-5 py-sm-0 py-2">
                    <NavLink className="text-white nav-link fw-bold" to="/invest">
                      INVEST
                    </NavLink>
                  </li>
                  <li className="nav-item ms-5 py-sm-0 py-2">
                    <NavLink className="text-white nav-link fw-bold" to="/claim">
                      CLAIM
                    </NavLink>
                  </li>
                  <li className="nav-item ms-5 py-sm-0 py-2">
                    <NavLink className="text-white nav-link fw-bold" to="/account">
                      ACCOUNT DETAIL
                    </NavLink>
                  </li>
                </>
              )}
              {!account && (
                <li className="nav-item ms-5 py-sm-0 py-2 dropdown">
                  <div
                    className="d-col-button btn text-white p-0 ps-3"
                    onClick={activateBrowserWallet}
                  >
                    <strong>LOGIN</strong>
                    <img
                      src="/images/dapp-icon.svg"
                      style={{
                        width: 40,
                        height: 40,
                        marginTop: -1,
                        marginRight: -1,
                        marginLeft: 10,
                      }}
                    />
                  </div>
                </li>
              )}
              {!!account && (
                <li className="nav-item ms-5 py-sm-0 py-2 dropdown">
                  <div
                    className="d-col-button btn text-white p-0 ps-3"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {account.slice(0, 4)}
                    ...
                    {account.slice(-4)}
                    <img
                      src="/images/dapp-icon.svg"
                      style={{
                        width: 40,
                        height: 40,
                        marginTop: -1,
                        marginRight: -1,
                        marginLeft: 10,
                      }}
                    />
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{ minWidth: '200px' }}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          navigator.clipboard.writeText(account);
                          toast('Copy success', { type: 'success' });
                        }}
                      >
                        <span className="fw-lighter" style={{ fontSize: '14px' }}>
                          Wallet connected with:
                        </span>
                        <p className="mb-0 d-flex align-items-center">
                          <img src="/images/fox.svg" style={{ width: '18px', height: '18px' }} />
                          <span className="fw-bold mx-2">
                            {account.slice(0, 4)}
                            ...
                            {account.slice(-4)}
                          </span>
                          <img src="/images/copy.svg" style={{ width: '12px', height: '12px' }} />
                        </p>
                      </a>
                    </li>
                    <hr className="dropdown-divider" />
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          deactivate();
                          history.replace('/');
                        }}
                      >
                        <p className="mb-0 d-flex align-items-center">
                          <img
                            src="/images/log-out.svg"
                            style={{ width: '18px', height: '18px' }}
                          />
                          <span className="fw-bold mx-2">Log out</span>
                        </p>
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {children}
    </>
  );
};

export default StandardLayout;
