import { useContractFunction as useContractFunctionCore } from '@usedapp/core';
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { contractABI } from './config';

const useContractFunction = (method: string) => {
  return useContractFunctionCore(
    new Contract(process.env.REACT_APP_CONTRACT_ADDRESS || '', new ethers.utils.Interface(contractABI)),
    method,
  );
};

export default useContractFunction;
