import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { DAppProvider } from '@usedapp/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import StandardLayout from './layouts/Standard';
import Account from './pages/Account';
import Claim from './pages/Claim';
import Invest from './pages/Invest';

const config = {
  readOnlyChainId: parseInt(process.env.REACT_APP_CHAIN_ID || '0'),
  readOnlyUrls: {
    [process.env.REACT_APP_CHAIN_ID || '0']: process.env.REACT_APP_CHAIN_URL || '',
  },
};

function App() {
  return (
    <DAppProvider config={config}>
      <Router>
        <StandardLayout>
          <Switch>
            <Route exact path="/">
              <Redirect to="/invest" />
            </Route>
            <Route exact path="/invest">
              <Invest />
            </Route>
            <Route exact path="/claim">
              <Claim />
            </Route>
            <Route exact path="/account">
              <Account />
            </Route>
            <Route path="*">
              <Redirect to="/invest" />
            </Route>
          </Switch>
        </StandardLayout>
      </Router>
      <ToastContainer position="top-left" theme="dark" />
    </DAppProvider>
  );
}

export default App;
