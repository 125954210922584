import React from 'react';

const defaultStringDate = '0 days 00h : 00m : 00s';
const useCountDown = (endDate: Date) => {
  const [stringDate, setStringDate] = React.useState(defaultStringDate);
  React.useEffect(() => {
    if (!endDate) return;
    const interval = setInterval(() => {
      const now = new Date();
      let time = Math.floor((endDate.getTime() - now.getTime()) / 1000);
      if (time <= 0) {
        clearInterval(interval);
        setStringDate(defaultStringDate);
        return;
      }

      const seconds = time % 60;
      time = (time - seconds) / 60;

      const minutes = time % 60;
      time = (time - minutes) / 60;

      const hours = time % 24;
      time = (time - hours) / 24;

      const days = time;

      const stringDate =
        days +
        ' days ' +
        hours.toString().padStart(2, '0') +
        'h : ' +
        minutes.toString().padStart(2, '0') +
        'm : ' +
        seconds.toString().padStart(2, '0') +
        's';
      setStringDate(stringDate);
    }, 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  return stringDate;
};

export default useCountDown;
