import { BigNumber } from 'ethers';

export const formatNumber = (number: string | number) => {
  number = number + '';
  number = number.replace('.', 'x');
  number = number.replace(/\d(?=(\d{3})+(x|$))/g, '$&,');
  number = number.replace('x', '.');
  return number;
};

export const convertBigNumberToNumber = (bigNumber?: BigNumber) => {
  if (!bigNumber) return null;
  return bigNumber
    .div(10 ** 6)
    .div(10 ** 6)
    .div(10 ** 6)
    .toNumber();
};

export const convertNumberToBigNumber = (number: number | string) => {
  return BigNumber.from(number)
    .mul(10 ** 6)
    .mul(10 ** 6)
    .mul(10 ** 6);
};
